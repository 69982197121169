import React from "react";

const tooltipItems = {
  market: [
    'Рынок соискателя',
    'Преимущественно рынок соискателя',
    'Рынок в балансе',
    'Преимущественно рынок работодателя',
    'Рынок работодателя'
  ],
  marketDescription: [
    'вакансий больше, чем кандидатов',
    'кандидатов немного не хватает',
    'спрос и предложение равны',
    'конкуренции за кандидатов почти нет',
    'кандидатов больше, чем вакансий',
  ],
  marketHints: [
    [
      'Расширять воронку сверху. Например, можно искать кандидатов в других регионах и любого возраста.',
      'Продумать комфортные условия для кандидата — гибкий график, удалённую работу, неполный день.',
      'Убедиться, что зарплата не меньше, чем у конкурентов.'
    ],
    [
      'Указать в вакансии, чем компания отличается от конкурентов: хорошим соцпакетом, лояльным руководством, отсутствием бюрократии.',
      'Расширять воронку сверху. Например, можно искать кандидатов в других регионах и любого возраста.',
      'Продумать комфортные условия для кандидата — гибкий график, удалённую работу, неполный день.',
    ],
    [
      'Предлагать зарплату не ниже, чем у конкурентов.',
      'Указать в вакансии, чем компания отличается от конкурентов: хорошим соцпакетом, лояльным руководством, отсутствием бюрократии.',
      'Оперативно реагировать на отклики, чтобы вас не опередили другие работодатели.'
    ],
    [
      'Указать в вакансии, чем компания отличается от конкурентов: хорошим соцпакетом, лояльным руководством, отсутствием бюрократии.',
      'Попробовать инструменты продвижения, чтобы вакансия была заметнее. На Авито можно повысить количество просмотров объявления, увеличить его или выделить цветом зарплату.',
    ],
    [
      'Работать над брендом работодателя, чтобы привлекать лучших кандидатов. Например, повысить узнаваемость, снизить «текучку», продумать соцпакет.',
      'Автоматизировать подбор сотрудников. Например, на Авито можно делегировать первичный отбор чат-боту.',
    ]
  ]
}

function getArrayIndex(index: number): number {
  if (index <= 2) return 0;
  if (index <= 4) return 1;
  if (index <= 6) return 2;
  if (index <= 8) return 3;
  return 4;
}

function getArrayElementByIndex(index: number, itemName: string): string | string[] {
  const arrayIndex: number = getArrayIndex(index);
  // @ts-ignore
  return tooltipItems[itemName][arrayIndex];
}

export default function IndexTooltip({ index }: { index: number }) {
  return (
    <div className="info-hint__text median-hint hint">
      <div className="hint__item">
        <div className="hint__title">
          Индекс: { Math.floor(index * 10) / 10 }
        </div>
        <div className="hint__subtitle">
          <b>{ getArrayElementByIndex(index, 'market') }</b>
          { getArrayElementByIndex(index, 'marketDescription') }
        </div>
        <div className="hint__description">
          <div className="hint__description-title">
            Что делать?
          </div>
          <ul className="hint__list">
            { [...getArrayElementByIndex(index, 'marketHints')].map((item: string) => (
              <li className="hint__list-item" key={item}>
                { item }
              </li>
            )) }
          </ul>
        </div>
      </div>
    </div>
  )
}
