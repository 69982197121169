/* eslint-disable */
import React, {useEffect, useState} from "react";
import FormInput from "./formInput";
import {validations} from "./formValidations";
import data from '../cities.json';

interface DropdownInputProps {
  value: string,
  onInput: (arg1: string) => void,
  name: string,
  onInvalid: (arg1: boolean, arg2: string) => void,
  placeholder: string,
}

export default function DropdownInput({ value, onInput, name, onInvalid, placeholder } : DropdownInputProps) {
  const [filteredCities, setFilteredCities] = useState<string[]>([]);
  const [isCitySelected, setIsCitySelected] = useState<boolean>(true);

  useEffect(() => {
    if (value.length < 3) {
      setFilteredCities([]);
      return;
    }
    setFilteredCities(data.cities.filter((city) => city.toLowerCase().startsWith(value.toLowerCase())));
  }, [value]);

  function onCitySelect(city: string): void {
    setIsCitySelected(true);
    onInput(city);
  }

  function onCityInput(city: string): void {
    if (isCitySelected) setIsCitySelected((isSelected) => !isSelected);
    onInput(city);
  }

  return (
    <div className="call-form__city">
      <FormInput
        value={value}
        onInput={onCityInput}
        label="Город"
        validations={validations.city}
        name={name}
        onInvalid={onInvalid}
        placeholder={placeholder}
      />
      {isCitySelected || value.length < 3 ? '' :
        <ul className="call-form__dropdown">
          {filteredCities.length ?
            filteredCities.map((city: string) => (
              <li className="call-form__dropdown-item" key={city}>
                <button onClick={() => onCitySelect(city)} type="button">
                  { city }
                </button>
              </li>
            )) :
            value.length > 2 ? <li>В нашем списке нет такого города</li> : ''
          }
        </ul>
      }
    </div>
  )
}
