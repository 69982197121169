/* eslint-disable */
import { useEffect, useRef} from "react";

export default function useUpdateEffect(callback: () => void, deps: any[]) {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // @ts-ignore
      callback();
    }
    // @ts-ignore
  }, deps);
}
