/* eslint-disable */
import data from '../cities.json';

export interface Validation {
  errorMessage: string,
  validator: (arg1: string) => boolean,
}

const required = (value: string) => value.length > 0;

export const validations: { [key: string]: Validation[] } = {
  companyName: [
    {
      errorMessage: '&laquo;Название компании&raquo; обязательное поле.',
      validator: required,
    },
  ],
  name: [
    {
      errorMessage: 'Поле &laquo;Имя и&nbsp;фамилия&raquo; должно содержать только русские буквы',
      validator: (value) => /^[А-яЁё ]*$/g.test(value),
    },
    {
      errorMessage: '&laquo;Имя и&nbsp;фамилия&raquo; обязательное поле.',
      validator: required,
    }
  ],
  city: [
    {
      errorMessage: '&laquo;Город&raquo; обязательное поле.',
      validator: required,
    },
    {
      errorMessage: 'Город необходимо выбрать из списка.',
      validator: (value) => data.cities.filter((item) => item === value).length > 0,
    }
  ],
  email: [
    {
      validator: required,
      errorMessage: '&laquo;Электронная почта&raquo; обязательное поле.',
    },
    {
      validator: (value) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value),
      errorMessage: 'Неверный формат электронной почты.'
    },
  ],
  phone: [
    {
      validator: required,
      errorMessage: '&laquo;Телефон&raquo; обязательное поле',
    },
    {
      validator: (value) => value.replaceAll(/\D+/g, '').length === 11,
      errorMessage: 'Неверный формат телефона.',
    }
  ],
}
