/* eslint-disable */
import FormInput from "./formInput";
import React, {useMemo, useState} from "react";
import { validations } from "./formValidations";
import DropdownInput from "./dropdownInput";

interface CallFormProps {
  onError: () => void;
  onSuccess: () => void;
}

export default function CallForm({ onError, onSuccess }: CallFormProps) {
  const [companyName, setCompanyName] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const [invalidInputs, setInvalidInputs] = useState<string[]>([]);

  const formValue = useMemo(() => ({
    "slugParts": [
      "Работодателям",
      "Индекс рынка труда"
    ],
    "pageId": 2683,
    "fields": [
      {
        "name": "categoryId",
        "title": "Категория",
        "value": "111"
      },
      {
        "name": "company",
        "title": "Название компании",
        "value": companyName
      },
      {
        "name": "name",
        "title": "Имя и фамилия",
        "value": name,
      },
      {
        "name": "locationId",
        "title": "Город",
        "value": "637640"
      },
      {
        "name": "phone",
        "title": "Телефон",
        "value": phone
      },
      {
        "name": "email",
        "title": "Электронная почта",
        "value": email
      }
    ]
  }), [name, companyName, city, phone, email])

  function handleInvalid(isInvalid: boolean, inputName: string) {
    setInvalidInputs((currentInvalid) => {
      const inputs = [...currentInvalid].filter((input) => input !== inputName);
      if (isInvalid) inputs.push(inputName);
      return inputs;
    });
  }

  function submitHandler(e: SubmitEvent): void {
    e.preventDefault();
    if (!invalidInputs.length) {
      fetch(`/${process.env.REACT_APP_API_FEEDBACK_URL}`, {
        method: 'post',
        body: JSON.stringify(formValue),
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((response) => response.ok ? onSuccess() : onError());
    }
  }

  return (
    // @ts-ignore
    <form className="call-form" onSubmit={submitHandler}>
      <h3 className="call-form__title">
        Заказ звонка
      </h3>
      <p className="call-form__description">
        Менеджер свяжется с&nbsp;вами в&nbsp;течение суток по&nbsp;будням с&nbsp;10&nbsp;до&nbsp;18&nbsp;по&nbsp;московскому времени.
      </p>
      <div className="call-form__items">
        <FormInput
          value={companyName}
          onInput={setCompanyName}
          label="Название компании"
          validations={validations.companyName}
          name="company"
          onInvalid={handleInvalid}
          placeholder="Компания"
        />
        <FormInput
          value={name}
          onInput={setName}
          label="Имя и фамилия"
          name="name"
          validations={validations.name}
          onInvalid={handleInvalid}
          placeholder="Иван Иванов"
        />
        <DropdownInput
          value={city}
          onInput={setCity}
          name="locationId"
          onInvalid={handleInvalid}
          placeholder="Москва"
        />
        <FormInput
          value={phone}
          onInput={setPhone}
          label="Телефон"
          validations={validations.phone}
          name="phone"
          mask="+7 (999) 999 99 99"
          onInvalid={handleInvalid}
          placeholder=""
        />
        <FormInput
          value={email}
          onInput={(value) => setEmail(value.trim())}
          label={'Электронная почта'}
          validations={validations.email}
          name={'email'}
          onInvalid={handleInvalid}
          placeholder="Если у вас есть почта, привязанная к вашему профилю на Авито - укажите ее"
        />
      </div>
      <button
        className="btn btn-call"
        type="submit"
        disabled={invalidInputs.length > 0}
      >
        Отправить
      </button>
      <div className="modal__footer">
        Отправляя заявку, вы соглашаетесь с <a
          className="modal__footer-link"
          href="https://www.avito.ru/legal/rules/privacy-policy"
          target="_blank"
        >
          политикой обработки данных.
        </a>
      </div>
    </form>
  )
}
