import { CircularProgress } from "@mui/material";
import React from "react";

interface ILoaderProps {
  loading: boolean;
}

export default function Loader({loading}: ILoaderProps) {
  return loading ?
      <div className="loader">
        <CircularProgress className="progress" aria-busy={loading}/>
      </div>
      : null
}
